// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import styles from './Content.module.scss';
import Image from '../../Image';
import Grid from '@material-ui/core/Grid';


type Props = {
  body: string,
  slug: string,
  youtube: string,
  title: string
};

const Content = ({ body, title, slug, youtube }: Props) => (
  <div className={styles['content']}>
    <h1 className={styles['content__title']}>{title}</h1>

    <div style={{margin:"0 2% 2% 2%"}}>
      <div className={styles['youtube']}>
      <iframe width="1120" height="630" src={youtube} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen fs="0"></iframe>
      </div>
    </div>

    <Grid container alignItems="center" justify="center">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Image filename={slug.slice(1)+"1.png"} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Image filename={slug.slice(1)+"2.png"} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Image filename={slug.slice(1)+"3.png"} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Image filename={slug.slice(1)+"4.png"} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Image filename={slug.slice(1)+"5.png"} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Image filename={slug.slice(1)+"6.png"} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Image filename={slug.slice(1)+"7.png"} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Image filename={slug.slice(1)+"8.png"} />
          </Grid>
        </Grid>


    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
